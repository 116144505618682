export const SUPER_ADMIN_ROLE = 'SUPER_ADMIN';
export const KYC_OPS_ROLE = 'KYC_OPS';
export const KYC_SPV_ROLE = 'KYC_SPV';
export const SPECIALIST = 'SPECIALIST';
export const PRODUCT_BUSINESS_OPS = 'PRODUCT_BUSINESS_OPS';
export const CUSTOMER_SERVICE_OPS = 'CUSTOMER_SERVICE_OPS';
export const SUPPORT_ADMIN = 'SUPPORT_ADMIN';
export const FINANCE_OPS = 'FINANCE_OPS';
export const FINANCE_ADMIN = 'FINANCE_ADMIN';
export const CUSTOMER_OPS_SPV = 'CUSTOMER_OPS_SPV';
export const TECH_TEAM = 'TECH_TEAM';
export const GS_PRODUCT_TEAM = 'GS_PRODUCT_TEAM';
export const SALES_KYC = 'SALES_KYC';

export const DYNAMIC_ROLE_MANAGEMENT_SUPER_ADMIN_ROLE = 'SUPER_ADMIN';

export const TAB_KEY = 'TAB_KEY';
